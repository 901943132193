import React, { useEffect, useState } from 'react';
import './App.css';
import Move from "./Move";
import moment from "moment";
import {sortBy, debounce} from 'lodash';
import debounceRender from 'react-debounce-render';
import Group from "./Group";
import Status from "./Status";
import mixpanel from "mixpanel-browser";

const UserView = ({moves}) => {
    console.log("Rendering Dashboard", Object.keys(moves).length);
    window.moves = moves;
    console.profile();

    mixpanel.init("e70575c335fb2a267a13359ad92fed20", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
    });

    let isMobile = false;

    if (window.innerWidth < 1000) {
        isMobile = true;
        document.body.className = 'mobile';
    }

    // Variable sizes for everything
    // @todo Put this in it's own file and import where needed
    let scale = 1.5;

    const sizes = {
        headerFontSize: 20 * scale,
        trailerFontSize: 16 * scale,
        toFromValuesFontSize: 12 * scale,
        toFromWidth: 105 * scale,
        cardHeight: 30 * scale,
        cardWidth: 250 * scale,
        iconHeight: 20 * scale,
        bottomRowFontSize: 10 * scale,
        toFromTextFontSize: 8 * scale
    }

    // Set priorities
    // @todo This needs to be a shared util file because it is used in dashboard and in internal
    Object.keys(moves).forEach((i) => {
        if (!moves[i].priority) {
            moves[i].priority = 4;
            moves[i].priorityAutomatic = true;

            if (moves[i].is_ups) {
                moves[i].priority = 1;
            }

            if (moves[i].grounded_time) {
                let diff = moment().diff(
                    moment(moves[i].grounded_time),
                    'hours'
                );

                if (diff >= 36) {
                    moves[i].priority = 1;
                } else if (diff >= 24) {
                    moves[i].priority = 2;
                } else if (diff >= 12) {
                    moves[i].priority = 3;
                }
            }

            if (moves[i].is_bad) {
                moves[i].priority = 4;
            }
        }
    })

    // Group by status
    let m = Object.keys(moves).reduce((acc, curr) => {
        let move = moves[curr];
        if(!acc[move.status]) acc[move.status] = [];
        acc[move.status].push(move);
        return acc;
    }, {});

    // Group status groups by customer/ramp/driver
    if (m.RECEIVED) {
        m.RECEIVED = Object.keys(m.RECEIVED).reduce((acc, curr) => {
            let move = m.RECEIVED[curr];
            if(!acc[move.shipper]) acc[move.shipper] = [];
            acc[move.shipper].push(move);
            return acc;
        },{});
    }

    if (m.GROUNDED) {
        m.GROUNDED = Object.keys(m.GROUNDED).reduce((acc, curr) => {
            let move = m.GROUNDED[curr];
            if(!acc[move.from]) acc[move.from] = [];
            acc[move.from].push(move);
            return acc;
        },{});
    }

    if (m.ASSIGNED) {
        m.ASSIGNED = Object.keys(m.ASSIGNED).reduce((acc, curr) => {
            let move = m.ASSIGNED[curr];
            let driver = (move.driver && move.driver.nick_name) || 'None';
            if(!acc[driver]) acc[driver] = [];
            acc[driver].push(move);
            return acc;
        },{});
    }

    window.m=m;

    let shownMoves = [];

    let vh = window.innerHeight;
    let fullCardHeight = sizes.cardHeight + 12;
    let maxCards = Math.floor(vh / fullCardHeight);

    let currentRow = 1;

    let rules = {
        RECEIVED: ['priority', 'from'],
        ARRIVED: ['priority', 'from'],
        GROUNDED: ['priority', 'grounded_time', 'id'],
        ASSIGNED: ['assigned_time'],
        DELIVERED: ['id']
    }

    // Sort all of the groups and sub-groups
    Object.keys(m).forEach((status) => {
        if (!Array.isArray(m[status])) {
            Object.keys(m[status]).forEach((header) => {
                m[status][header] = sortBy(m[status][header], rules[status]);

                if (header !== "ASSIGNED") {
                    m[status][header] = sortBy(m[status][header], (o) => !o.is_bad);
                }
            });
        }
    });

    return (
        <>
            <div className="dashboard" style={{paddingTop: "60px"}}>
                <div style={{
                    backgroundColor: 'white',
                    color: 'black',
                    padding: '10px',
                    textAlign: 'center',
                    fontSize: '20px',
                    position: "fixed",
                    width: "100%",
                    height: "30px",
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                    borderBottom: "1px solid #c0c0c0"
                }}>
                    Important: Due to policy changes from Google, this site will stop functioning in June 2025. <a href="https://dispatch.centeratransport.com/help/legacy-dashboard-end-of-life-faq" target="_blank" rel="noreferrer">Learn more</a>
                </div>

                {m.RECEIVED &&
                    <Status status="RECEIVED" groups={m.RECEIVED} sizes={sizes} />
                }

                {m.ARRIVED &&
                    <Group name="ARRIVED" moves={m.ARRIVED} sizes={sizes} />
                }

                <div className="statusDivider"></div>

                {m.GROUNDED &&
                    <Status status="GROUNDED" groups={m.GROUNDED} sizes={sizes} />
                }

                <div className="statusDivider"></div>

                {m.ASSIGNED &&
                    <Status status="ASSIGNED" groups={m.ASSIGNED} sizes={sizes} />
                }
            </div>
        </>
    );
};

export default debounceRender(UserView, 500, {
    'leading': false,
    'trailing': true
});
